window.zeppelin ?= {}

elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax

# ----------------
scroll = undefined



setup_slide_toggler = (wrapper, onexpand = undefined, oncollapse = undefined) ->

    toggleable = wrapper.getElementsByClassName('slide-toggleable')[0]
    content = wrapper.getElementsByClassName('slide-toggleable-content')[0]
    cleanup = ->
        toggleable.style.maxHeight = ''
    shrink = ->
        toggleable.style.maxHeight = 0
    toggle = ->
        h = content.offsetHeight
        toggleable.style.maxHeight = "#{h}px"
        if wrapper.classList.contains('expanded')
            wrapper.classList.remove('expanded')
            window.setTimeout(shrink, 1)
            window.setTimeout(cleanup, 520)
            if oncollapse
                oncollapse()
        else
            wrapper.classList.add('expanded')
            window.setTimeout(cleanup, 520)
            if onexpand
                onexpand()
    return toggle


setup_faq_toggler = (faqitem) ->
    togglers = faqitem.getElementsByClassName('toggler')
    toggle = setup_slide_toggler(faqitem)
    observeMulti(togglers, 'click', toggle)


faq_setup = ->
    faqitems = elByClass("article-faq-item")

    unless faqitems?.length > 0
        console.log("No faqitems")
        return

    for faqitem in faqitems
        setup_faq_toggler(faqitem)



setup_subsection_toggling = (subsection) ->

    observe(subsection, 'focus', ->
        console.log("Got focus")
        subsection.classList.add('expanded')
    )
    toggler = subsection.getElementsByClassName('subsection-toggler')[0]
    doScroll = ->
        scroll.animateScroll(subsection)
    toggle = setup_slide_toggler(subsection, doScroll)
    observe(toggler, 'click', (ev) ->
        if window.zeppelin.gt_medium.matches
            doScroll()
            console.log("Do nothing")
        else
            ev.preventDefault()
            toggle()
            # if subsection.classList.contains('expanded')
            #     subsection.classList.remove('expanded')
            # else
            #     subsection.classList.add('expanded')
            #     scroll.animateScroll(subsection)
            return false

    )


subsections_setup = ->
    scroll = new SmoothScroll('a[href*="#"]')
    subsections = elByClass("subsection-block")

    unless subsections?.length > 0
        console.log("No subsection blocks")
        return

    console.log("Found subsections")
    for subsection in subsections
        setup_subsection_toggling(subsection)

    currHash = window.location.hash
    if currHash
        elById(currHash.substring(1))?.classList.add("expanded")


observe(document, "DOMContentLoaded", (event) ->
    console.info("subsection INIT")
    subsections_setup()
    faq_setup()
)

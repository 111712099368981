window.zeppelin ?= {}

elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax


reflist_setup = ->
    references = elByClass('theanchor')

    unless references?.length > 0
        # don't bother looking for /ref/ links if there is no references
        # onsole.log("No references")
        return

    console.time("update-ref-links")
    links = elByTag('a')
    for link in links
        href = link.getAttribute('href')
        if href?.length > 0
            ref = href.replace(/^\/ref\//, '#ref-')
            if href != ref
                link.href = ref
    console.timeEnd("update-ref-links")

    undefined


observe(document, "DOMContentLoaded", (event) ->
    console.info("reflist INIT")
    reflist_setup()
)

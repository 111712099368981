window.zeppelin ?= {}

elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax

# ----------------
body = undefined

menuToggler = ->
    menu_wrap = elById("the_menu_wrap")
    return unless menu_wrap

    toggler = elById("menu_toggler")
    observe(toggler, "click", ->
        body.classList.toggle("menu-visible")
    )

observe(document, "DOMContentLoaded", (event) ->
    console.info("menu INIT")
    body = elByTag('body')[0]
    menuToggler()
)

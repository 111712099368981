# window.zeppelin ?= {}
#
# elByTag = window.zeppelin.elByTag
# elById = window.zeppelin.elById
# elByClass = window.zeppelin.elByClass
# elByQuery = window.zeppelin.elByQuery
# observe = window.zeppelin.observe
# observeMulti = window.zeppelin.observeMulti
# newEl = window.zeppelin.newEl
# doAjax = window.zeppelin.doAjax
#
# # ----------------
#
# # setup_slide_toggler = (wrapper, onexpand = undefined, oncollapse = undefined) ->
# #
# #     toggleable = wrapper.getElementsByClassName('slide-toggleable')[0]
# #     content = wrapper.getElementsByClassName('slide-toggleable-content')[0]
# #     toggle = (clear = false) ->
# #         if wrapper.classList.contains('expanded')
# #             wrapper.classList.remove('expanded')
# #             toggleable.style.maxHeight = 0
# #             if oncollapse
# #                 oncollapse()
# #         else
# #             h = content.offsetHeight + 32
# #             toggleable.style.maxHeight = "#{h}px"
# #             wrapper.classList.add('expanded')
# #             if onexpand
# #                 onexpand()
# #     return toggle
# #
# #
# # setup_faq_toggler = (faqitem) ->
# #     togglers = faqitem.getElementsByClassName('toggler')
# #     toggle = setup_slide_toggler(faqitem)
# #     observeMulti(togglers, 'click', toggle)
# #
# #
# # faq_setup = ->
# #     faqitems = elByClass("article-faq-item")
# #
# #     unless faqitems?.length > 0
# #         console.log("No faqitems")
# #         return
# #
# #     for faqitem in faqitems
# #         setup_faq_toggler(faqitem)
#
#
# observe(document, "DOMContentLoaded", (event) ->
#     console.info("faq INIT")
#     # faq_setup()
# )

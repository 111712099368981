window.zeppelin ?= {}

elByTag = window.zeppelin.elByTag
elById = window.zeppelin.elById
elByClass = window.zeppelin.elByClass
elByQuery = window.zeppelin.elByQuery
observe = window.zeppelin.observe
observeMulti = window.zeppelin.observeMulti
newEl = window.zeppelin.newEl
doAjax = window.zeppelin.doAjax

# ----------------
body = undefined

toc_setup = ->
    toc_boxes = elByClass("toc-box")
    toc_targets = elByClass("toc-target")

    unless toc_boxes?.length > 0
        # onsole.debug("No toc element")
        return
    unless toc_targets?.length > 0
        console.warn("Could not create table of contents since there is no chapters on page")
        return
    body.classList.add("has-toc")
    idmapping = {}
    frag = document.createDocumentFragment()
    for target in toc_targets
        do (target) ->
            id = target.id
            titleEl = target.getElementsByClassName('thetitle')[0]
            title = titleEl?.innerHTML
            titleClass = titleEl?.getAttribute('class')
            if idmapping[id]
                console.warn("""
                    Found duplicate id for chapter: '#{title}' (id: '#{id}')
                """)
                idmapping[id] += 1
                id = "#{id}-#{idmapping[id]}"
                target.id = id
            idmapping[id] = 1
            toclink = newEl("a", "toc-el #{titleClass}", title)
            toclink.href = "##{id}"
            frag.appendChild(toclink)
            # onsole.debug("Found target: ", id, title)

    for toc in toc_boxes
        toc.appendChild(frag.cloneNode(true))
        toc.style.display = 'block'


observe(document, "DOMContentLoaded", (event) ->
    # onsole.info("toc INIT")
    body = elByTag('body')[0]
    toc_setup()

)
